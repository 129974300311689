var namespace = namespace || {};

namespace.method = (function(){
	"use strict";
	
	function init(){
		console.log("init method on namespace");
	}
	

	return{
		init:init
	}
}());

$(document).ready(function () {
	namespace.method.init()

})
